import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import ContactTeaser from '../components/ContactTeaser/ContactTeaser';
import PostNav from '../components/PostNav/PostNav';
import Seo from '../components/Seo';
import { Episode } from './Episode';
import Section from '../components/Section/Section';

const EpisodePage = ({ data, location }) => {
  const { markdownRemark, previous, next } = data;
  const { excerpt, html, frontmatter } = markdownRemark;
  const { title, seoTitle, description, date, redcircleID, text1, text2 } =
    frontmatter;

  return (
    <Layout location={location}>
      <Seo
        location={location}
        title={seoTitle || title}
        description={description || excerpt}
      />
      <Episode
        date={date}
        title={title}
        body={<div dangerouslySetInnerHTML={{ __html: html }} />}
        text1={text1}
        text2={text2}
        redcircleID={redcircleID}
      />
      <PostNav previous={previous} next={next} />
      <Section>
        <ContactTeaser />
      </Section>
    </Layout>
  );
};

export default EpisodePage;

export const pageQuery = graphql`
  query EpisodeBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        seoTitle
        description
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
        redcircleID
        text1
        text2
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      # frontmatter {
      #   title
      # }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        # title
        date
      }
    }
  }
`;
