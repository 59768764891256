import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import { PodcastPlayer } from '../components/PodcastPlayer/PodcastPlayer';
import Section from '../components/Section/Section';

export const Episode = ({ title, body, date, redcircleID, text1, text2 }) => {
  return (
    <article
      className="episode"
      itemScope
      itemType="http://schema.org/PodcastEpisode"
    >
      <ContentHeader title={title} date={date} />

      <Section>
        {text1 ? <ReactMarkdown>{text1}</ReactMarkdown> : <div>{body}</div>}
        {redcircleID && <PodcastPlayer redcircleID={redcircleID} />}
        <ReactMarkdown>{text2}</ReactMarkdown>
      </Section>
    </article>
  );
};
